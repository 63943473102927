// 获取地址栏信息
export default function getUrlAddressInfo() {
  let url = window.location.href;
  url = decodeURIComponent(window.location.href);
  try {
    let urlStr = url.split('?')[1];
    let obj = {};
    let paramsArr = urlStr.split('&');
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=');
      obj[arr[0]] = arr[1];
    }
    return obj;
  } catch {
    return;
  }
}
