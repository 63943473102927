/*
 * @Author: huangtian1_v 1246562955@qq.com
 * @Date: 2024-01-29 21:19:45
 * @LastEditors: huangtian1_v 1246562955@qq.com
 * @LastEditTime: 2024-04-24 13:40:14
 * @FilePath: \enc-pc\src\utils\errorCode.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  400: '请求错误(400)',
  401: '未授权，请重新登录(401)',
  403: '拒绝访问(403)',
  404: '请求出错(404)',
  408: '请求超时(408)',
  500: '系统繁忙，请稍后重试',
  501: '服务未实现(501)',
  502: '网络错误(502)',
  503: '服务不可用(503)',
  504: '网络超时(504)',
  505: 'HTTP版本不受支持(505)',
  // '-10000': '',
  101: '请求异常', // 任何场景，都可能会有此异常
  120070: '用户银行卡信息绑定异常', //使用默认银行卡进行支付时
  120071: '获取税信息失败', //1.调用询价接口 2.支付
  // 120075: '邮编和州不匹配',
  120079: '当前无主套餐，无法下单', //1.个人中心，用户选择套餐点击购买 2.选择完套餐，到购买页，点击支付
  120080: '用户存在待生效套餐，无法订购主套餐', //1.个人中心，用户选择流量包点击购买 2.选择完流量包，到购买页，点击支付
  120082: 'SIM卡数量超出最大购买限制', // 1.购卡进行SIM卡数量切换 2.支付页点击支付
  120081: '套餐账户余额不足', // 1.个人中心下单，选择套餐账户支付
  120021: '请输入正确ICCID信息', // 激活校验
  120015: '系统找不到订单，请重新核对订单信息', // 激活校验
  120016: '系统找不到订单，请重新核对订单信息', // 激活校验
  120014: '卡已经激活，请勿重复激活', // 激活校验
  // 120017: '订单信息有误', // 激活校验 20220721：废除
  120018: 'SIM卡已过期，无法激活', // 激活校验
  120020: ' SIM卡激活中，请耐心等待', // 激活校验
  120022: '此卡不是线下领取的手机卡，请输入正确ICCID信息', // 线下激活
  120023: '此卡不是线上领取的手机卡，请输入正确ICCID信息', // 线上激活
  // 120019: '激活码错误',
  120073: '中美双号开通失败，请稍后再试', // 中美双号开通
  120074: '双号资源不足', // 激活校验
  // 90008: '用户不存在或非在网用户', // 用户有效性校验
  // 90009: '用户已注册', // 用户是否注册(需要分场景 注册场景:用户如果是已注册则不允许进行二次注册   密码重置场景: 用户未注册,不可以进行密码重置)
  // 90005: '短信验证码错误，请重新输入', // 验证码校验
  // 90004: '旧密码输入错误', //  个人中心修改密码
  // eslint-disable-next-line no-dupe-keys
  90001: '数据异常', // 个人中心新增银行卡
  90007: '您处于保号期暂时无法受理业务，若需办理业务，需要取消保号服务',
  90008: '用户不存在或非在网用户', // 中美双号开通
  90009: '',
  90056: '您已提交过,无需重复提交哦！',
  90057: '', // 登录
  90068: '用户名或密码错误',
  // 120087: '查询订单过期',
  120088: '业务专属账户支付失败',

  // 学生活动
  120084: '该邮箱已经参加过一次活动，请勿重复参加',
  120085: '该银行卡已经参加过一次活动，请勿重复参加',
  120086: '请勿重复提交表单',
  120077: '邮编填写错误',
  120106: '绑卡失败',
  120111: '',
  120112: '',
  120104: ''
}
