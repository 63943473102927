import { getToken } from '@/utils/auth'

const navigation = (router, store, purchaseCardMeta) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('setNoHeaderFooter', to.meta.noHeaderFooter)
    if (to.meta.metaInfo) {
      // 套餐页
      if (to.path == '/plans/500mb-plan') {
        store.dispatch('setMeta', purchaseCardMeta[to.query.id - 1])
      } else {
        store.dispatch('setMeta', to.meta.metaInfo)
      }
    } else {
      store.dispatch('setMeta', '')
    }
    // 获取token
    const hasToken = getToken()
    const hasPermission = to.meta.hasPermission
    if (hasPermission && !hasToken) {
      next(`/login`)
    } else {
      next()
    }
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    // 判断是否是短信用户
    const tokenInfo = store?.state?.login?.tokenInfo || {}
    const hasIsSmsUser = to.meta.hasIsSmsUser
    if (tokenInfo && tokenInfo.isSmsUser === 1 && hasIsSmsUser) {
      next('/personal')
    } else {
      next()
    }
  })
}

export { navigation }
