import { setToken } from '@/utils/auth'
import { setStore, getStore } from '@/utils/store'

const login = {
  state: {
    token: getStore({ name: 'token' }) || '',
    forget: true,
    titleActive: true,
    tokenInfo: {} // 登录后接口信息
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token, type: 'session' })
    },
    // token登录后信息
    SET_TOKEN_INFO: (state, payload) => {
      state.tokenInfo = payload
    },
    SET_FORGET(state, forget) {
      state.forget = forget
    },
    SET_TITLE_ACTIVE(state, titleActive) {
      state.titleActive = titleActive
    }
  },
  actions: {
    setToken({ commit }, payload) {
      commit('SET_TOKEN', payload?.access_token || '')
      commit('SET_TOKEN_INFO', payload)
    }
  }
}

export default login
