import { setStore, getStore } from '@/utils/store'

const activation = {
  state: {
    bundleInfo: getStore({ name: 'bundleInfo' }) || {},
  },
  mutations: {
    SET_BUNDLEINFO: (state, bundleInfo) => {
      state.bundleInfo = bundleInfo
      setStore({ name: 'bundleInfo', content: state.bundleInfo })
    },
  },
  actions: {
    setBundleInfo({ commit }, bundleInfo) {
      commit('SET_BUNDLEINFO', bundleInfo)
    },
  }
}

export default activation
