import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { register } from '@/plugins/register'
import i18n from '@/locales/index'
// 按需加载elementui
import '@/utils/element'
// 样式重置reset
import '@/styles/reset.scss'
// elementui自定义主题色
import '@/styles/element-variables.scss'
// 全局组件插件方法注册
register(Vue)

// 挂载路由导航守卫
import { purchaseCardMeta } from './utils/metaInfo'
import { navigation } from './plugins/navigation'
navigation(router, store, purchaseCardMeta)

window.AppVue = new Vue({
  router,
  store,
  i18n,
  metaInfo() {
    // let flag = navigator.userAgent.match(
    //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    // )
    if (this.$store.state.changeMeta.changeMeta) {
      return {
        title: this.$store.state.changeMeta.changeMeta.title,
        meta: [
          {
            name: this.$store.state.changeMeta.changeMeta.name,
            content: this.$store.state.changeMeta.changeMeta.content
          },
          {
            name: 'description',
            content: this.$store.state.changeMeta.changeMeta.description
          }
        ]
      }
    } else {
      return {
        title: '迎客移动',
        meta: [
          {
            name: 'viewport',
            content: '',
            keywords: '',
            description: ''
          }
        ]
      }
    }
  },
  render: (h) => h(App)
}).$mount('#app')
