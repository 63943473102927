import Meta from 'vue-meta'
import ElementUI, { Message } from 'element-ui'
import Mixin from '@/utils/mixins.js'
import VueClipboard from 'vue-clipboard2'
import i18n from '@/locales/index'
import WeChat_ReturnUrl from './wechatReturnUrl'

// 根据IP地址获取国家
const getUserRegion = () => {
  // 获取用户浏览器的首选语言
  const language = navigator.language || navigator.userLanguage

  // 返回语言中的地区部分
  if (language) {
    return language.split('-')[1].toLowerCase()
  } else {
    return 'en'
  }
}

// 设置语言
const setLang = (lang) => {
  if (lang && lang === sessionStorage.getItem('lang')) {
    return false
  }
  let site = getUserRegion() === 'cn' ? 'zh' : getUserRegion() === 'tw' ? 'tw' : 'en'
  const value = lang || site
  i18n.locale = value
  if (value !== sessionStorage.getItem('lang')) location.reload()
  sessionStorage.setItem('lang', value)
}

function isEnglish() {
  return sessionStorage.getItem('lang') == 'en'
}

function isFanTi() {
  return sessionStorage.getItem('lang') == 'tw'
}

// 统一注册全局组件方法
const register = (Vue) => {
  // Meta
  Vue.use(Meta)

  // 全局注册element-ui组件
  Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value),
    size: 'mini'
  })
  Vue.prototype.$message = Message

  // 微信支付返回地址
  Vue.prototype.$WeChat_ReturnUrl = WeChat_ReturnUrl.WeChat_ReturnUrl

  Vue.prototype.$isEnglish = isEnglish()
  Vue.prototype.$isFanTi = isFanTi()
  Vue.prototype.$setLang = setLang

  //判断手机还是pc
  Vue.prototype.$getDev = () => {
    return window.screen.width <= 767 ? 'mobile' : 'pc'
  }

  // 复制
  VueClipboard.config.autoSetContainer = true // autoSetContainer需要设置为true
  Vue.use({
    install() {
      Vue.component('Mixin', Mixin)
    }
  })
  Vue.use(VueClipboard)

  Vue.config.productionTip = false
}

export { register }
