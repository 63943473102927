import { setStore, getStore } from '@/utils/store'

const changeMeta = {
  state: {
    changeMeta: getStore({ name: 'changeMeta' }) || {}
  },
  mutations: {
    CHANGE_META: (state, changeMeta) => {
      state.changeMeta = changeMeta
      setStore({ name: 'changeMeta', content: state.changeMeta, keywords: state.changeMeta || '', description: state.description || '' })
    }
  },
  actions: {
    setMeta({ commit }, changeMeta) {
      commit('CHANGE_META', changeMeta)
    }
  }
}

export default changeMeta
