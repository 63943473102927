<template>
  <div class="header">
    <div class="top">
      <div
        class="header_top_con_l_icon"
        v-for="(val, key) in iconList"
        :key="key"
      >
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="`#icon-${val.icon}`" />
        </svg>
        <span>{{ val.text }}</span>
      </div>
    </div>
    <div class="menu">
      <div class="icon" @click="openMenu">
        <span></span>
      </div>
      <div class="img">
        <img
          @click="toRoute('/')"
          style="cursor: pointer"
          src="@/assets/images/header/logo.svg"
          alt=""
        />
      </div>
      <div class="personal">
        <el-dropdown @command="handleCommand">
          <i class="el-icon-user"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(i, key) in actionList"
              :key="key"
              :command="i"
              >{{ i.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- �˵�չ������ -->
    <div :class="isMenuOpen ? 'active mask' : 'mask'" @click="closeMenu"></div>
    <div :class="isMenuOpen ? 'active menu_con' : 'menu_con'">
      <div class="inner">
        <i class="el-icon-circle-close" @click="closeMenu"></i>
        <div class="logo">
          <img
            @click="toRoute('/')"
            style="cursor: pointer"
            src="@/assets/images/header/logo.svg"
            alt=""
          />
        </div>
        <div class="itmes">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
          >
            <div v-for="item in tabList" :key="item.id">
              <el-submenu v-if="item.children" :index="item.id">
                <template slot="title">
                  <span>{{ item.title }}</span>
                </template>
                <el-menu-item-group>
                  <div v-for="subitem in item.children" :key="subitem.id">
                    <el-menu-item :index="subitem.id">
                      <span @click="toRoute(subitem.path)">{{
                        subitem.title
                      }}</span></el-menu-item
                    >
                  </div>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="item.id">
                <span @click="toRoute(item.path)" slot="title">{{
                  item.title
                }}</span>
              </el-menu-item>
            </div>
          </el-menu>
        </div>
        <div class="btns">
          <el-button
            type="primary"
            v-if="!hasToken"
            @click="toRoute('/login')"
            >{{ $t('components.Headerh5.5m6ksbh4jmc0') }}</el-button
          >
          <el-button type="primary" v-else @click="toLogout()">{{
            $t('components.Headerh5.5m6ksbh4kek0')
          }}</el-button>
          <el-button type="warning" @click="toRoute('/activation/first')">{{
            $t('components.Headerh5.5m6ksbh4kj80')
          }}</el-button>
        </div>
        <div class="change_lang">
          <el-dropdown @command="(command) => (lanageName = command)">
            <span>
              {{ lanageName }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(lanage, key) in lanageList"
                :key="key"
                :command="lanage"
                >{{ lanage }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('components.Headerh5.5m6ksbh4kmg0')"
      :visible.sync="visible"
      width="80%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button class="del-btn" type="primary" @click="handleLogout">{{
          $t('components.Headerh5.5m6ksbh4kpc0')
        }}</el-button>
        <el-button type="primary" @click="visible = false">{{
          $t('components.Headerh5.5m6ksbh4kt40')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { logout } from '@/http/login'
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      iconList: [
        {
          icon: 'send',
          text: this.$t('components.Headerh5.5m6ksbh4kwg0')
        },
        {
          icon: 'cost',
          text: this.$t('components.Headerh5.5m6ksbh4l0w0')
        },
        {
          icon: 'procedures',
          text: this.$t('components.Headerh5.5m6ksbh4l480')
        }
      ],
      activeMenu: '0',
      tabList: [
        {
          id: '1',
          title: this.$t('components.Headerh5.5m6ksbh4l7c0'),
          path: '/plans/propagate'
        },
        {
          id: '2',
          title: this.$t('components.Headerh5.5m6ksbh4lak0'),
          path: '/personal/doubleNumber/publicity'
        },
        {
          id: '3',
          title: this.$t('components.Headerh5.5m6ksbh4ldw0'),
          path: '/'
        },
        {
          id: '4',
          title: this.$t('components.Headerh5.5m6ksbh4lso0'),
          path: '/coverage'
        },
        {
          id: '5',
          title: this.$t('components.Headerh5.5m6ksbh4m080'),
          path: '/',
          children: [
            {
              id: '5-1',
              title: this.$t('components.Headerh5.5m6ksbh4m3k0'),
              path: '/helpSupport/commonProblem'
            },
            {
              id: '5-2',
              title: this.$t('components.Headerh5.5m6ksbh4lso0'),
              path: '/coverage'
            },
            {
              id: '5-3',
              title: this.$t('components.Headerh5.5m6ksbh4m8g0'),
              path: '/tracking'
            },
            {
              id: '5-4',
              title: this.$t('components.Headerh5.5m6ksbh4mc00'),
              path: '/rates'
            }
          ]
        }
      ],
      actionList: [
        {
          name: this.$t('components.Headerh5.5m6ksbh4mf80'),
          path: '/personal/myinfo'
        },
        {
          name: this.$t('components.Headerh5.5m6ksbh4mio0'),
          path: '/personal/businessManegement/bundleChange'
        },
        {
          name: this.$t('components.Headerh5.5m6ksbh4mnk0'),
          path: '/callrecord'
        }
      ],
      lanageList: [this.$t('components.Headerh5.5m6ksbh4mrw0'), 'English'],
      lanageName: this.$t('components.Headerh5.5m6ksbh4mrw0'),
      isMenuOpen: false,
      visible: false
    }
  },
  computed: {
    hasToken() {
      const token = this.$store.getters.token
      return token
    }
  },
  methods: {
    toRoute(path) {
      this.isMenuOpen = false

      const { path: url } = this.$route

      if (path === url) {
        this.$store.commit('SET_FORGET', true)
        this.$store.commit('SET_TITLE_ACTIVE', true)
        return
      }

      this.$router.push({
        path
      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    openMenu() {
      this.isMenuOpen = true
    },
    toLogout() {
      this.visible = true
    },
    handleLogout() {
      logout().then((res) => {
        if (res.code === '200') {
          this.visible = false
          this.isMenuOpen = false
          this.$message.success(res.message)
          this.$store.dispatch('setToken', {})
          removeToken()
          this.hasToken = ''
          this.$router.push({
            path: '/'
          })
        } else {
          this.visible = false
          this.$message.error(res.message)
        }
      })
    },
    handleCommand(i) {
      if (this.hasToken) {
        //�ѵ�¼״̬
        this.$router.push({
          path: i.path
        })
      } else {
        this.$router.push({
          path: '/login'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/h5/common/header.scss';
</style>
