<!-- modal -->
<template>
  <!-- 模态框 -->
  <div id="myModal" class="modal" v-show="showModal">
    <!-- 模态内容 -->
    <div class="modal-content">
      <img
        class="close"
        src="@/assets/images/common/modal-close.svg"
        @click="showModal = false"
        alt=""
      />
      <img
        src="@/assets/images/common/recommend-bg2.png"
        class="button"
        alt=""
        @click="showModal = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitLoading: false,

      showModal: false
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
/* 模态框背景 */
.modal {
  display: block; /* 默认隐藏 */
  position: fixed; /* 固定位置 */
  z-index: 100; /* 位于顶层 */
  left: 0;
  top: 0;
  width: 100%; /* 宽度100%覆盖整个屏幕 */
  height: 100%; /* 高度100%覆盖整个屏幕 */
  overflow: auto; /* 如果需要滚动条 */
  background-color: rgb(0, 0, 0); /* 背景颜色 */
  background-color: rgba(0, 0, 0, 0.65); /* 背景颜色，带有透明度 */

  /* 关闭按钮 */
  .close {
    color: #aaa;
    float: right;
    width: 40px;
    height: 40px;
    position: relative;
    top: 40px;
    right: 20px;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  /* 模态内容框 */
  .modal-content {
    margin: 8% auto 0; /* 15% 从顶部开始，水平居中 */
    width: 440px;
    height: 410.001px;
    position: relative;
    background: url(~@/assets/images/common/recommend-bg1.png) no-repeat;

    .button {
      width: 247.137px;
      height: 48px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      bottom: 38px;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .modal {
    .close {
      color: #aaa;
      float: right;
      width: 8vw;
      height: 8vw;
      position: relative;
      top: 6.3333vw;
      right: 2.6667vw;
      cursor: pointer;
    }
    .modal-content {
      margin: 35vw auto 0;
      width: 74.8vw;
      height: 70.5932vw;
      position: relative;
      background: url(~@/assets/images/common/recommend-bg1.png) no-repeat
        center / cover;

      .button {
        width: 49.8667vw;
        height: 8.5333vw;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        bottom: 6.0667vw;
        cursor: pointer;
      }
    }
  }
}
</style>
