import { setStore, getStore } from '@/utils/store'

const ifHeaderFooter = {
  state: {
    noHeaderFooter: getStore({ name: 'noHeaderFooter' }) || {}
  },
  mutations: {
    NO_HEADER_FOOTER: (state, noHeaderFooter) => {
      state.noHeaderFooter = noHeaderFooter
      setStore({ name: 'noHeaderFooter', content: state.noHeaderFooter })
    }
  },
  actions: {
    setNoHeaderFooter({ commit }, noHeaderFooter) {
      commit('NO_HEADER_FOOTER', noHeaderFooter)
    }
  }
}

export default ifHeaderFooter
