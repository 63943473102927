/*
 * @Author: huangtian1_v 1246562955@qq.com
 * @Date: 2024-05-21 19:36:03
 * @LastEditors: huangtian1_v 1246562955@qq.com
 * @LastEditTime: 2024-05-21 22:12:36
 * @FilePath: \enc-pc\src\http\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 登录注册模块api
import request from './axios'
//图形验证码
export const getCodeImg = (row) => {
  return request({
    url: '/gateway/pcapp/createImg',
    method: 'get',
    params: row,
    responseType: 'blob'
  })
}
//给用户发送验证码
export const sendMobileCode = (row) => {
  return request({
    url: '/gateway/pcapp/api/v1/loginorregist/sendMobileVerificationCode',
    method: 'post',
    data: row
  })
}

//用户验证码检验
export const checkMobileCode = (row) => {
  return request({
    url: '/gateway/pcapp/api/v1/loginorregist/checMobilekVerificationCode',
    method: 'post',
    data: row
  })
}
//检验用户是否在网
export const checkInNet = (row) => {
  return request({
    url: '/gateway/pcapp/api/v1/loginorregist/checkInNet',
    method: 'get',
    params: row
  })
}
//检验用户是否注册
export const checkIsRegistered = (row) => {
  return request({
    url: '/gateway/pcapp/api/v1/loginorregist/checkIsRegistered',
    method: 'get',
    params: row
  })
}
//用户注册及密码修改 - 没登录情况
export const registerOrResetUser = (data, code) => {
  return request({
    url: '/gateway/pcapp/api/v1/loginorregist/registerOrResetUser',
    method: 'post',
    data,
    headers: {
      'IMAGE-CODE': code.code,
      'X-IMAGE-CODE-TOKEN': code.value
    }
  })
}
// 登录后情况
export const modifyPwd = (data, code) => {
  return request({
    url: '/gateway/pcapp/api/v1/personalcenter/modifyPwd',
    method: 'post',
    data,
    headers: {
      'IMAGE-CODE': code.code,
      'X-IMAGE-CODE-TOKEN': code.value
    }
  })
}
// 获取token
export const login = (params, code) => {
  return request({
    url: '/gateway/customer.core/oauth/token',
    method: 'post',
    params: params,
    headers: {
      'IMAGE-CODE': code.code,
      'X-IMAGE-CODE-TOKEN': code.value
    },
    auth: {
      username: 'enc2022@user',
      password: 'enc2022'
    }
  })
}

//退出登录
export const logout = (params) => {
  return request({
    url: '/gateway/customer.core/exit',
    method: 'delete',
    params
  })
}
