// 首页模块api
import request from './axios'

// 首页套餐
export const bundleList = (data) => {
  return request({
    url: '/gateway/pcapp/api/v1/order/bundleList',
    method: 'post',
    data
  })
}

export const offerLocking = (data) => {
  return request({
    url: '/gateway/pcapp/api/v1/common/offerLocking',
    method: 'post',
    data
  })
}
// 获取合作伙伴数据
export const getPartners = (row) => {
  return request({
    url: '/gateway/pcapp/api/v1/common/partners',
    method: 'get',
    data: row
  })
}
