export default {
  400: '请求错误(400)',
  401: '未授权，请重新登录(401)',
  403: '拒绝访问(403)',
  404: '请求出错(404)',
  408: '请求超时(408)',
  500: '服务器错误',
  501: '服务未实现(501)',
  502: '网络错误(502)',
  503: '服务不可用(503)',
  504: '网络超时(504)',
  505: 'HTTP版本不受支持(505)',
  120021: '请输入正确ICCID信息',
  120019: '激活码不正确',
  120015: '系统找不到订单，请重新核对订单信息',
  120016: '系统找不到订单，请重新核对订单信息',
  120014: '卡已经激活，请勿重复激活',
  120018: 'SIM卡已过期，无法激活',
  120020: 'SIM卡激活中，请耐心等待',
  120080: '当前已存在待生效套餐，无法订购主套餐',
  120092: '活动太火爆了，请稍后再试。',
  120017: '订单信息有误',
  120091: ''
  // 90008: '用户名或密码错误',
  // '-10000': '验证码错误',
}
