import Vue from 'vue'
import enProductLocale from './en-us.json'
import zhProductLocale from './zh-cn.json'
import twProductLocale from './zh-tw.json'
import enLang from 'element-ui/lib/locale/lang/en.js' // 英文
import zhLang from 'element-ui/lib/locale/lang/zh-CN.js'
import twLang from 'element-ui/lib/locale/lang/zh-TW.js'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: sessionStorage.getItem('lang') || 'zh',
  messages: {
    en: {
      ...enProductLocale,
      ...enLang
    },
    zh: {
      ...zhProductLocale,
      ...zhLang
    },
    tw: {
      ...twProductLocale,
      ...twLang
    }
  },
  silentFallbackWarn: true //控制台上不打印警告c
})

export default i18n
