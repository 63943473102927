const purchaseCardMeta = [
  {
    //基础套餐
    title: '最便宜的美国手机卡套餐 | 迎客移动ENC Mobile基础套餐',
    // name: 'viewport',
    // content: 'width=device-width,initial-scale=1.0',
    keywords: '',
    description:
      '迎客移动ENC Mobile基础套餐是美国最便宜的手机通讯套餐，每月话费低至$9.99，无限美国通话、无限中国通话和全球短信，500MB 5G流量和无限基础流量。'
  },
  {
    //进阶套餐
    title: '性价比最高的美国手机卡套餐 | 迎客移动ENC Mobile进阶套餐',
    // name: 'viewport',
    // content: 'width=device-width,initial-scale=1.0',
    keywords: '',
    description:
      '迎客移动ENC Mobile进阶套餐是性价比最高的美国手机卡套餐，每月话费低至$14.99，无限美国通话、中国通话和全球短信，2GB 5G流量和无限基础流量。'
  },
  {
    //乐享套餐
    title: '最受欢迎的美国手机卡套餐 | 迎客移动ENC Mobile乐享套餐',
    // name: 'viewport',
    // content: 'width=device-width,initial-scale=1.0',
    keywords: '',
    description:
      '购买迎客移动ENC Mobile乐享美国手机通讯套餐，每月话费低至$19.99，无限美国通话、中国通话和全球短信，3GB 5G流量和无限基础流量。'
  },
  {
    //流量套餐
    title: '美国手机卡5G流量套餐 | 迎客移动ENC Mobile',
    // name: 'viewport',
    // content: 'width=device-width,initial-scale=1.0',
    keywords: '',
    description:
      '迎客移动ENC Mobile手机卡流量套餐畅享6GB 5G高速流量，每月话费低至$25.99，无限美国通话、无限中国通话和全球短信和无限基础流量。'
  },
  {
    //高级套餐
    title: '美国手机卡无限流量套餐 | 迎客移动ENC Mobile高级套餐',
    // name: 'viewport',
    // content: 'width=device-width,initial-scale=1.0',
    keywords: '',
    description:
      '迎客移动ENC Mobile高级手机通讯套餐畅享15GB 5G高速流量，每月话费低至$35.99，无限美国通话、无限中国通话和全球短信和无限基础流量。'
  }
]
export { purchaseCardMeta }
