import { setStore, getStore } from '@/utils/store'

const personal = {
  state: {
    personalData: getStore({ name: 'personalData' }) || {},
    openState: getStore({ name: 'getStore' }) || ''
  },
  mutations: {
    PERSONAL_DATA: (state, personalData) => {
      state.personalData = personalData
      setStore({ name: 'personalData', content: state.personalData })
    },
    DOUBLE_NUMBER_OPEN: (state, openState) => {
      state.openState = openState
      setStore({ name: 'openState', content: state.openState })
    }
  },
  actions: {
    setPersonalData({ commit }, personalData) {
      commit('PERSONAL_DATA', personalData)
    }
  }
}

export default personal
