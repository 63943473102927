import Vue from 'vue'
import { Loading } from 'element-ui'

export default Vue.mixin({
  methods: {
    jump(path) {
      return this.$router.push(path)
    },
    deleteFn(row, success, error) {
      console.log('删除', row)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          if (success) success()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
          if (error) error()
        })
    },
    // 开启loding
    startLoadingMixins(loadState = null, loadingText) {
      loadState = null
      //使用Element loading-start 方法
      loadState = Loading.service({
        lock: true,
        text: loadingText || '加载中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loadingclass'
      })
      return loadState
    },
    // 结束loding
    endLoadingMixins(loadState) {
      //使用Element loading-close 方法
      loadState && loadState.close()
    }
  }
})
