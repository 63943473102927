import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import login from './modules/login'
import personal from './modules/personal'
import ifHeaderFooter from './modules/noHeaderFooter'
import changeMeta from './modules/changeMeta'
import activation from './modules/activation'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    login,
    personal,
    ifHeaderFooter,
    changeMeta,
    activation
  },
  getters
})
